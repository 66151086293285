import { CreateUserDto, User } from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

// Fetch all users
export const getUsers = async () => {
  const response = await AxiosInstance.users.usersControllerFindAll();
  return response.data.items;
};

export const createUser = async (userData: CreateUserDto) => {
  const response =
    await AxiosInstance.users.usersControllerCreateUser(userData);
  return response.data;
};

// Update a user
export const updateUser = async (id: string, userData: CreateUserDto) => {
  const response = await AxiosInstance.users.usersControllerUpdate(
    id,
    userData,
  );
  return response.data;
};

// Delete a user
export const deleteUser = async (user: User) => {
  await AxiosInstance.users.usersControllerDeleteUser(user._id);
};
