import * as yup from 'yup';
import { isCRMApp } from '../../environment/app.type';
import { regex } from '../regex.utils';
import { ESpecialty } from '../../backend/careo-api';

const candidateSchema = yup.object().shape({
  recruiterId: isCRMApp
    ? yup.string().required('Recruiter is required.')
    : yup.string().optional(),
  officerId: !isCRMApp
    ? yup.string().required('Officer is required.')
    : yup.string().optional(),

  address: yup
    .object()
    .shape({
      street: yup
        .string()
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        ),
      city: yup
        .string()
        .matches(regex.name, 'City is not valid')
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        ),
      county: yup
        .string()
        .min(1, 'County is required')
        .matches(regex.name, 'County is not valid')
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        ),
      region: yup
        .string()
        .min(1, 'Region is required')
        .matches(regex.name, 'Region is not valid')
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        ),
      country: yup
        .string()
        .min(1, 'Country is required')
        .matches(regex.name, 'Country is not valid')
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        ),
      zip: yup
        .string()
        .matches(regex.name, 'ZIP code is not valid')
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        ),
    })
    .transform((value, originalValue) => (!originalValue ? undefined : value)),

  title: yup.string(),
  firstName: yup
    .string()
    .required('First name is required')
    .matches(
      regex.name,
      'First Name must contain only letters, spaces, apostrophes, and hyphens',
    ),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(
      regex.name,
      'Last Name must contain only letters, spaces, apostrophes, and hyphens',
    ),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email address is required'),
  phoneNumber: yup
    .string()
    .matches(
      regex.phoneNumber,
      'Phone number must start with a + sign, followed by 9 to 15 digits',
    )
    .required('Phone number is required.'),
  homePhoneNumber: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(
      regex.phoneNumber,
      'Phone number must start with a + sign, followed by 9 to 15 digits',
    ),
  workPhoneNumber: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(
      regex.phoneNumber,
      'Phone number must start with a + sign, followed by 9 to 15 digits',
    ),
  birthDate: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  nationality: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  gender: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  payAmount: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Pay Amount must be a valid number.')
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .positive('Pay Amount must be a positive number'),
  niNumber: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .matches(
      /^[a-zA-Z0-9\s]*$/,
      'National Insurance number must contain only alphanumeric characters',
    ),
  hiredStatus: yup.string(),
  department: yup
    .string()
    .optional()
    .transform((value, originalValue) => (!originalValue ? undefined : value))
    .oneOf(Object.values(ESpecialty), 'Invalid department.'),
  designation: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  currentlyEmployed: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  drivingLicence: yup.boolean(),
  ownCar: yup.boolean(),
  payrollProviderId: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),
  grade: yup
    .string()
    .transform((value, originalValue) => (!originalValue ? undefined : value)),

  professionalRegistrations: yup.array().of(
    yup.object().shape({
      authority: yup
        .string()
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        )
        .required('Professional Registration Authority is required'),
      number: yup
        .string()
        .transform((value, originalValue) =>
          !originalValue ? undefined : value,
        )
        .required('Professional Registration Number is required'),
    }),
  ),
});

export { candidateSchema };
