import {
  useQuery,
  useMutation,
  UseMutationResult,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  SendWhatsappTemplateDto,
  WhatsappTemplate,
} from '../../backend/careo-api';
import {
  fetchApprovedTemplates,
  sendApprovedTemplateToListOfRecipients,
} from '../api/whatsapp.api';

type UseUserProps = {
  isLoading: boolean;
  whatsappApprovedTemplates: WhatsappTemplate[];

  sendApprovedTemplateToListOfCandidates: UseMutationResult<
    void,
    any,
    SendWhatsappTemplateDto,
    unknown
  >;
};
const WHATSAPP_TEMPLATES_QUERY_KEY = 'WHATSAPP_TEMPLATES_QUERY_KEY';
export const useWhatsapp = (): UseUserProps => {
  // Fetch whatsapp approved templates (React Query caches it)
  const { data: whatsappApprovedTemplates = [], isLoading } = useQuery({
    queryKey: [WHATSAPP_TEMPLATES_QUERY_KEY],
    queryFn: fetchApprovedTemplates,
  });

  // send selected approved whatsapp template to list of candidates
  const sendApprovedTemplateToListOfCandidates = useMutation({
    mutationFn: (sendWhatsappTemplateDto: SendWhatsappTemplateDto) =>
      sendApprovedTemplateToListOfRecipients(sendWhatsappTemplateDto),
    onSuccess: () => {
      toast.success('Template messages batch sent successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  return {
    whatsappApprovedTemplates,
    isLoading,
    sendApprovedTemplateToListOfCandidates,
  };
};
