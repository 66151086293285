import { useWhatsapp } from '../../api-query/hooks/useWhatsapp';
import { Select } from '../ui';
import { useConfirm } from '../../contexts/confirm-modal.context';
export type SendWhatsappTemplateModalExtraData = {
  selectedTemplateSID: string;
};
export const SendWhatsappTemplate = ({
  selectedCandidatesIds,
}: {
  selectedCandidatesIds: string[];
}) => {
  const { whatsappApprovedTemplates } = useWhatsapp();
  const { setExtraData } = useConfirm<SendWhatsappTemplateModalExtraData>();

  return (
    <>
      Select Template and send it to <b>{selectedCandidatesIds.length}</b>{' '}
      selected candidates{' '}
      <div
        style={{
          fontSize: '15px',
        }}
      >
        <Select
          label="Approved Templates"
          required
          options={whatsappApprovedTemplates.map((template) => ({
            label: template.friendlyName,
            value: template.sid,
          }))}
          data-testid="approved-template-select"
          onChange={(item) => {
            setExtraData({
              selectedTemplateSID: item as string,
            });
          }}
        />
      </div>
    </>
  );
};

export default SendWhatsappTemplate;
