import React, { createContext, useContext, useState, ReactNode } from 'react';
import { ConfirmModal } from '../components';

type ConfirmContextType<T = unknown> = {
  openConfirm: (modalConfig: ConfirmConfig<T>) => void;
  closeConfirm: () => void;
  setExtraData: (extraData: T) => void;
};

type ConfirmConfig<T = unknown> = {
  title: string;
  confirmBtnTitle?: string;
  component: ReactNode;
  onConfirm: (extraData?: T) => void;
};

const ConfirmContext = createContext<ConfirmContextType | undefined>(undefined);

export const ConfirmProvider = ({ children }: { children: ReactNode }) => {
  const [modalConfig, setConfirmConfig] = useState<ConfirmConfig | null>(null);
  const [_extraData, _setExtraData] = useState<unknown>();

  const openConfirm = (config: ConfirmConfig) => {
    setConfirmConfig(config);
  };

  const closeConfirm = () => {
    setConfirmConfig(null);
  };

  const setExtraData = (extraData: unknown) => {
    _setExtraData(extraData);
  };

  return (
    <ConfirmContext.Provider
      value={{ openConfirm, closeConfirm, setExtraData }}
    >
      {children}

      {modalConfig && (
        <ConfirmModal
          isOpen={!!modalConfig}
          title={modalConfig.title}
          confirmBtnTitle={modalConfig.confirmBtnTitle}
          onConfirm={() => modalConfig.onConfirm(_extraData)}
          onNegativeBtnClick={() => closeConfirm()}
        >
          {modalConfig.component}
        </ConfirmModal>
      )}
    </ConfirmContext.Provider>
  );
};

export function useConfirm<T = unknown>() {
  const context = useContext(ConfirmContext) as
    | ConfirmContextType<T>
    | undefined;
  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmProvider');
  }
  return context;
}
