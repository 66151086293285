import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { SidebarContainer } from './sidebar.style';
import {
  breakpoint,
  footerSidebarItems,
  CrmSidebarItems,
  AllFeaturesSidebarItems,
  InsourcingSidebarItems,
} from '../../../constants';
import { useLocation, Link } from 'react-router-dom'; // Import useLocation from React Router
import { AppIcon } from '../../../icons';
import { useCompany } from '../../../api-query/hooks/useCompany';
import { CompanySubscriptionFeatures } from '../../../backend/careo-api';

interface SidebarProps {
  isSidebarActive: boolean;
  setIsSidebarActive: Dispatch<SetStateAction<boolean>>;
}

export const Sidebar = ({
  isSidebarActive,
  setIsSidebarActive,
}: SidebarProps) => {
  const location = useLocation();

  const { subscriptionFeatures } = useCompany();

  const sidebarItems = useMemo(() => {
    const hasCrm = subscriptionFeatures?.includes(
      CompanySubscriptionFeatures.CRM,
    );
    const hasInsourcing = subscriptionFeatures?.includes(
      CompanySubscriptionFeatures.INSOURCING,
    );

    if (hasCrm && hasInsourcing) {
      return AllFeaturesSidebarItems;
    }

    if (hasInsourcing) {
      return InsourcingSidebarItems;
    }

    // if (hasCrm) {
    //   return CrmSidebarItems;
    // }

    return CrmSidebarItems;
  }, [subscriptionFeatures]);

  return (
    <SidebarContainer
      isSidebarActive={isSidebarActive}
      id="sidebar"
      data-testid="sidebar-container"
    >
      <nav className="sidebar-container" data-testid="sidebar-nav">
        <div className="sidebar-header" data-testid="sidebar-header">
          <div className="logo-container" data-testid="logo-container">
            <AppIcon data-testid="app-icon" />
            <div
              className="toggle-sidebar"
              onClick={() => setIsSidebarActive(false)}
              data-testid="toggle-sidebar"
            >
              <i
                className="bx bx-menu bx-x"
                id="header-toggle"
                data-testid="toggle-icon"
              ></i>
            </div>
          </div>
          <div
            className="sidebar-list-container"
            data-testid="sidebar-list-container"
          >
            {sidebarItems
              .filter((el) => !el.isHidden)
              .map((item) => {
                const isActive = location.pathname.includes(item.route);
                return (
                  <Link
                    to={item.route}
                    key={item.key}
                    className={`sidebar-item ${isActive ? 'active' : ''} ${item.isDisabled ? 'disabled' : ''}`}
                    onClick={() => {
                      // Hide sidebar on mobile when a link is clicked
                      if (window.innerWidth <= breakpoint.mobile) {
                        setIsSidebarActive(false);
                      }
                    }}
                    data-testid={`sidebar-item-${item.key}`} // Added test ID for sidebar item
                  >
                    {item.icon({})}
                    <span>{item.title}</span>
                    <span className="tooltip">{item.title}</span>
                  </Link>
                );
              })}
          </div>
        </div>
        <div className="sidebar-footer" data-testid="sidebar-footer">
          <div
            className="sidebar-list-container"
            data-testid="footer-sidebar-list-container"
          >
            {footerSidebarItems
              .filter((el) => !el.isHidden)
              .map((item) => {
                const isActive = location.pathname === item.route;
                return (
                  <Link
                    to={item.route}
                    key={item.key}
                    className={`sidebar-item ${isActive ? 'active' : ''} ${item.isDisabled ? 'disabled' : ''}`}
                    onClick={() => {
                      // Hide sidebar on mobile when a link is clicked
                      if (window.innerWidth <= breakpoint.mobile) {
                        setIsSidebarActive(false);
                      }
                    }}
                    data-testid={`footer-sidebar-item-${item.key}`} // Added test ID for footer sidebar item
                  >
                    {item.icon({})}
                    <span>{item.title}</span>
                    <span className="tooltip">{item.title}</span>
                  </Link>
                );
              })}
          </div>
        </div>
      </nav>
    </SidebarContainer>
  );
};
