import React, { useEffect, useState } from 'react';
import {
  DeleteIcon,
  DownloadIcon,
  LeftArrowIcon,
  SortIcon,
} from '../../../icons';
import {
  ATag,
  Badge,
  Button,
  CardContainer,
  DragDropUpload,
  Table,
  TitlePage,
  ValidatePlacementForm,
  DocumentsListComponent,
  TabPageContainer,
} from '../../../components';
import { PlacementDetailsContainer } from './placement-details.style';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  calculateWeeksDifference,
  formatDate,
  uploadPlacementFiles,
  downloadFileByInvoice,
  downloadFileByTimesheet,
  formatCurrency,
  getStatus,
  ERoute,
  socket,
} from '../../../utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Application,
  EApplicationApprovalStatus,
  ESocketRefreshModule,
  ETimesheetStatus,
  EWebsocketType,
  HostedFile,
  Invoice,
  SocketRefreshData,
  Timesheet,
} from '../../../backend/careo-api';
import { useModal } from '../../../contexts/side-modal.context';
import { useConfirm } from '../../../contexts/confirm-modal.context';

export enum EPlacementDetailsTabs {
  GENERAL = 'General',
  DOCUMENTS = 'Documents',
  TIMESHEETS = 'Timesheets',
  INVOICES = 'Invoices',
}

const tabs = [
  EPlacementDetailsTabs.GENERAL,
  EPlacementDetailsTabs.TIMESHEETS,
  EPlacementDetailsTabs.INVOICES,
  EPlacementDetailsTabs.DOCUMENTS,
];

export const PlacementDetailsPage = () => {
  const { id } = useParams();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const location = useLocation();

  const { openConfirm, closeConfirm } = useConfirm();

  const onDeleteConfirm = (item: Timesheet) => {
    openConfirm({
      title: 'Delete Timesheet',
      component: (
        <>
          <div data-testid="confirm-modal-message">
            Do you want to delete
            <b data-testid="timesheet-bold-text">this timesheet</b>?
          </div>
        </>
      ),
      onConfirm: () =>
        deleteTimesheet(item)
          .then(() => closeConfirm())
          .catch(() => {}),
    });
  };

  const [selectedTab, setSelectedTab] = useState(EPlacementDetailsTabs.GENERAL);
  const [placement, setPlacement] = useState<Application | null>(null);
  const [timesheetsList, setTimesheetsList] = useState<Timesheet[]>([]);
  const [invoicesList, setInvoices] = useState<Invoice[]>([]);
  const [documents, setDocuments] = useState<HostedFile[]>([]);

  const deleteTimesheet = async (timesheet: Timesheet) => {
    await AxiosInstance.timesheets
      .timesheetsControllerDelete(timesheet._id)
      .then(() => {
        toast.success('Timesheet Deleted Successfully.');
        getTimesheets();
        closeConfirm();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickGoBack = () => {
    navigate(-1);
  };

  const onClickValidate = (placement: Application) => {
    openModal({
      title: 'Validate Placement',
      component: (
        <ValidatePlacementForm
          application={placement}
          data-testid="validate-placement-form"
        />
      ),
    });
  };

  const getPlacementDetails = () => {
    AxiosInstance.applications
      .applicationsControllerGetApplicationById(id!)
      .then((response) => {
        setPlacement(response.data);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getTimesheets = async () => {
    AxiosInstance.timesheets
      .timesheetsControllerFindAll({
        placementId: id!,
      })
      .then((response) => {
        const result = response.data.items;
        setTimesheetsList(result);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getInvoices = async () => {
    AxiosInstance.invoices
      .invoicesControllerFindAll({
        placementId: id!,
      })
      .then((response) => {
        const result = response.data.items;
        setInvoices(result);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getPlacementDocuments = () => {
    AxiosInstance.files
      .filesControllerFindPlacementDocs(id!)
      .then((result) => {
        setDocuments(result.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const handleFileChange = async (files: File[]) => {
    await uploadPlacementFiles(id!, files)
      .then(() => {
        toast.success('Documents uploaded successfully');
        getPlacementDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickDelete = async (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getPlacementDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  useEffect(() => {
    const handlePlacementChange = ({
      module,
      moduleId = '',
    }: SocketRefreshData) => {
      if (module === ESocketRefreshModule.PLACEMENTS && id === moduleId) {
        getPlacementDetails();
        getTimesheets();
        getInvoices();
      }
    };

    socket.on(EWebsocketType.Refresh, handlePlacementChange);
    return () => {
      socket.off(EWebsocketType.Refresh, handlePlacementChange);
    };
  }, [socket]);

  useEffect(() => {
    setSelectedTab(
      (location.pathname.split('/')?.[3] as EPlacementDetailsTabs) ||
        EPlacementDetailsTabs.GENERAL,
    );
  }, [location]);

  useEffect(() => {
    getPlacementDetails();
    getTimesheets();
    getInvoices();
    getPlacementDocuments();
  }, []);

  if (!placement) {
    return <></>;
  }

  return (
    <>
      <PlacementDetailsContainer data-testid="placement-details-container">
        <div className="placement-header" data-testid="placement-header">
          <div
            className="back-button"
            onClick={onClickGoBack}
            data-testid="back-button"
          >
            <LeftArrowIcon />
          </div>
          <TitlePage data-testid="placement-title">Detail Placement</TitlePage>
        </div>

        <CardContainer
          className="placement-information-container"
          data-testid="placement-information-container"
        >
          <TabPageContainer data-testid="tab-page-container">
            <div className="tab-items-list" data-testid="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item text-capitalize ${
                    el === selectedTab && 'active'
                  }`}
                  onClick={() => navigate(`/${ERoute.PLACEMENTS}/${id}/${el}`)}
                  data-testid={`tab-item-${el}`}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div
              className="tab-content-container"
              data-testid="tab-content-container"
            >
              {selectedTab === EPlacementDetailsTabs.GENERAL && (
                <div className="info-card" data-testid="general-tab-content">
                  <div
                    className="info-card-header"
                    data-testid="info-card-header"
                  >
                    <div
                      className="info-card-title"
                      data-testid="info-card-title"
                    >
                      Placed Candidate
                    </div>
                    {placement.approvalStatus ===
                      EApplicationApprovalStatus.NOT_ACTIVE && (
                      <Button
                        type="primary"
                        onClick={() => onClickValidate(placement)}
                        data-testid="validate-button"
                      >
                        Validate
                      </Button>
                    )}
                  </div>
                  <hr />
                  <div
                    className="info-card-content row"
                    data-testid="info-card-content"
                  >
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-fullname"
                    >
                      <div className="info-item-title col-4">Full Name</div>
                      <div className="info-item-data col-8">
                        {placement.candidate?.firstName}{' '}
                        {placement.candidate?.lastName}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="info-item-gender"
                    >
                      <div className="info-item-title col-4">Gender</div>
                      <div className="info-item-data col-8">
                        {placement.candidate?.gender || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="info-item-dob">
                      <div className="info-item-title col-4">Date of Birth</div>
                      <div className="info-item-data col-8">
                        {placement.candidate?.birthDate
                          ? formatDate(placement.candidate?.birthDate)
                          : '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Pay Amount</div>
                      <div className="info-item-data col-8">
                        {placement.job?.corePrice
                          ? `${formatCurrency(placement.job?.corePrice)} per hour`
                          : '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Nationality</div>
                      <div className="info-item-data col-8">
                        {placement.candidate?.nationality || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4"> Charge</div>
                      <div className="info-item-data col-8">
                        {placement.fee
                          ? `${formatCurrency(placement.fee)} per hour`
                          : '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Email Address</div>
                      <div className="info-item-data col-8">
                        {placement.candidate?.email}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Length of contract
                      </div>
                      <div className="info-item-data col-8">
                        {
                          calculateWeeksDifference(
                            new Date(placement.availableFrom),
                            new Date(placement.availableTo),
                          ).label
                        }
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Phone Number</div>
                      <div className="info-item-data col-8">
                        {placement.candidate?.phoneNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Hours per week{' '}
                      </div>
                      <div className="info-item-data col-8">
                        {placement.job?.hoursPerWeek}{' '}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Start Date</div>
                      <div className="info-item-data col-8">
                        {formatDate(placement.availableFrom)}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">End Date</div>
                      <div className="info-item-data col-8">
                        {formatDate(placement.availableTo)}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4"> VAT</div>
                      <div className="info-item-data col-8">
                        {placement.vat ? placement.vat + '%' : '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Margin per hour
                      </div>
                      <div className="info-item-data col-8">
                        {placement.fee
                          ? `${formatCurrency(
                              placement.fee - placement.job?.corePrice,
                            )}`
                          : '-'}
                      </div>
                    </div>

                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Expected contract Margin
                      </div>
                      <div className="info-item-data col-8">
                        {placement.fee
                          ? formatCurrency(
                              Number(placement.fee - placement.job?.corePrice) *
                                Number(
                                  calculateWeeksDifference(
                                    new Date(placement.availableFrom),
                                    new Date(placement.availableTo),
                                  ).value,
                                ) *
                                Number(placement.job?.hoursPerWeek),
                            )
                          : '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        On Call Active Rate
                      </div>
                      <div className="info-item-data col-8">
                        {placement.onCallActiveRate
                          ? `${formatCurrency(placement.onCallActiveRate)}  per hour`
                          : '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        On Call Charge
                      </div>
                      <div className="info-item-data col-8">
                        {placement.onCallFee
                          ? `${formatCurrency(placement.onCallFee)}  per hour`
                          : '-'}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === EPlacementDetailsTabs.DOCUMENTS && (
                <div data-testid="documents-tab-content">
                  <div
                    className="info-card"
                    data-testid="personal-documents-card"
                  >
                    <div
                      className="info-card-title"
                      data-testid="personal-documents-title"
                    >
                      Personal Documents
                    </div>
                    <hr />
                    <DocumentsListComponent
                      documents={documents}
                      onClickDelete={onClickDelete}
                      getDocuments={getPlacementDocuments}
                      data-testid="documents-list"
                    />
                  </div>
                  <div
                    className="info-card"
                    data-testid="drag-drop-upload-card"
                  >
                    <DragDropUpload
                      onUpload={handleFileChange}
                      data-testid="drag-drop-upload"
                    />
                  </div>
                </div>
              )}
              {selectedTab === EPlacementDetailsTabs.TIMESHEETS && (
                <div
                  className="data-table-container"
                  data-testid="timesheets-table-container"
                >
                  <Table data-testid="timesheets-table">
                    <thead>
                      <tr data-testid="timesheets-table-header">
                        <th
                          className="checkbox-table"
                          data-testid="checkbox-column"
                        >
                          <input type="checkbox" />
                        </th>
                        <th data-testid="employee-name-column">
                          <div>
                            <label>Employee Name</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th data-testid="job-title-column">
                          <div>
                            <label>Job Title</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th data-testid="from-column">
                          <div>
                            <label>From</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>To</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Client Name</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Department</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Team</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Specialty</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Approval</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody data-testid="timesheets-table-body">
                      {timesheetsList.length ? (
                        timesheetsList.map((timesheet) => {
                          const isApproved =
                            timesheet.status === ETimesheetStatus.Approved;
                          return (
                            <tr
                              key={timesheet._id}
                              data-testid={`timesheet-row-${timesheet._id}`}
                            >
                              <td className="checkbox-table">
                                <input type="checkbox" />
                              </td>
                              <td>
                                <div className="name-item">
                                  <div>
                                    <div>
                                      {
                                        timesheet?.placement?.candidate
                                          ?.firstName
                                      }{' '}
                                      {
                                        timesheet?.placement?.candidate
                                          ?.lastName
                                      }
                                    </div>
                                    <div className="email">
                                      {timesheet?.placement?.candidate?.email}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="name-item">
                                  <div>
                                    <div>
                                      {' '}
                                      {timesheet?.placement?.job?.grade}
                                    </div>
                                    <div className="email">
                                      {timesheet?.placement?.job?.specialty}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {formatDate(
                                  timesheet?.timings?.[0]?.date ?? '',
                                )}
                              </td>
                              <td>
                                {formatDate(
                                  timesheet?.timings?.[
                                    timesheet.timings.length - 1
                                  ]?.date ?? '',
                                )}
                              </td>
                              <td>
                                {timesheet?.placement?.job?.client?.clientName}
                              </td>
                              <td>
                                <div className="name-item">
                                  <div>
                                    <div>
                                      {
                                        timesheet?.placement?.job?.client
                                          ?.firstName
                                      }{' '}
                                      {
                                        timesheet?.placement?.job?.client
                                          ?.lastName
                                      }
                                    </div>
                                    <div className="email">
                                      {timesheet?.placement?.job?.client?.email}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>{timesheet?.placement?.job?.grade}</td>
                              <td>{timesheet?.placement?.job?.specialty}</td>
                              <td>
                                <Badge
                                  type={getStatus(
                                    timesheet.status === 'Approved'
                                      ? 'Active'
                                      : timesheet.status === 'Rejected'
                                        ? 'On leave'
                                        : 'On boarding',
                                  )}
                                >
                                  {timesheet.status}
                                </Badge>
                              </td>
                              <td>
                                <div className="action-item">
                                  <div className="action-item">
                                    <div
                                      className={`download-icon ${
                                        isApproved ? '' : 'disabled'
                                      }`}
                                      onClick={() =>
                                        isApproved &&
                                        downloadFileByTimesheet(timesheet)
                                      }
                                    >
                                      <DownloadIcon />
                                    </div>
                                  </div>
                                  <div className="action-item">
                                    <div
                                      className={`delete-icon ${
                                        !isApproved ? '' : 'disabled'
                                      }`}
                                      onClick={() =>
                                        !isApproved &&
                                        onDeleteConfirm(timesheet)
                                      }
                                    >
                                      <DeleteIcon />
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={100}
                            className="text-center"
                            data-testid="no-items-found"
                          >
                            No item found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              )}
              {selectedTab === EPlacementDetailsTabs.INVOICES && (
                <div className="data-table-container">
                  <Table>
                    <thead>
                      <tr>
                        <th className="checkbox-table">
                          <input type="checkbox" />
                        </th>
                        <th>
                          <div>
                            <label>Invoice</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Type</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Generated</label>
                            <SortIcon />
                          </div>
                        </th>

                        <th>
                          <div>
                            <label>Due</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Status</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th>
                          <div>
                            <label>Approval</label>
                            <SortIcon />
                          </div>
                        </th>
                        <th className="action-th">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoicesList.length ? (
                        invoicesList.map((invoice) => {
                          const reminderDate = new Date(
                            invoice?.timesheet?.generatedDate,
                          );
                          reminderDate.setDate(reminderDate.getDate() + 30);

                          return (
                            <tr key={invoice.referenceNumber}>
                              <td className="checkbox-table">
                                <input type="checkbox" />
                              </td>
                              <td>Invoice-{invoice.referenceNumber}.pdf</td>
                              <td>Client</td>
                              <td>
                                {formatDate(invoice?.timesheet?.generatedDate)}
                              </td>
                              <td>{formatDate(reminderDate.toJSON())}</td>
                              <td>
                                <Badge type={'warning'}>Unpaid</Badge>
                              </td>
                              <td>{invoice?.timesheet.status}</td>
                              <td>
                                <div className="action-item">
                                  <div
                                    className="download-icon"
                                    onClick={() =>
                                      downloadFileByInvoice(invoice)
                                    }
                                  >
                                    <DownloadIcon />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={100}
                            className="text-center"
                            data-testid="no-items-found"
                          >
                            No item found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </TabPageContainer>
        </CardContainer>
      </PlacementDetailsContainer>
    </>
  );
};
