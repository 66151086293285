import React, { useState } from 'react';
import { Select, TitleCard, FieldText, Button, FormContainer } from '../ui';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateDepartmentClientDto } from '../../backend/careo-api';
import { specialtiesList, titles } from '../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  departmentSchema,
} from '../../utils';

type NewDepartmentFormProps = {
  getClientDetails: any;
  onCancel: () => void;
};

export const NewDepartmentForm = ({
  onCancel,
  getClientDetails,
}: NewDepartmentFormProps) => {
  const { id } = useParams();

  const {
    register,
    getValues,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm<CreateDepartmentClientDto>({
    resolver: yupResolver(departmentSchema as any),
  });

  const onClickClose = () => {
    onCancel();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = () => {
    setIsSubmitting(true);
    const values = getValues();

    AxiosInstance.clients
      .clientsControllerAddDepartment(id!, {
        ...values,
      })
      .then(() => {
        toast.success('Department added successfully');
        getClientDetails();
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
        setIsSubmitting(false);
      });
  };

  return (
    <FormContainer data-testid="form-container">
      <>
        <TitleCard data-testid="contact-title">Contact</TitleCard>
        <div className="row" data-testid="contact-name-row">
          <div className="col-md-2">
            <Select
              placeholder="Title"
              label="Title"
              options={titles.map((el) => ({ label: el, value: el }))}
              register={register('titleContact')}
              error={errors.titleContact}
              withoutChip
              data-testid="title-contact-select"
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="First Name"
              required
              register={register('firstNameContact', { required: true })}
              error={errors.firstNameContact}
              data-testid="first-name-contact-field"
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="Last Name"
              required
              register={register('lastNameContact', { required: true })}
              error={errors.lastNameContact}
              data-testid="last-name-contact-field"
            />
          </div>
        </div>
        <div className="row" data-testid="contact-department-team-row">
          <div className="col-md-6">
            <Select
              options={specialtiesList.map((el) => ({ label: el, value: el }))}
              placeholder="Select Specialty"
              label="Specialty"
              required
              register={register('specialty', { required: true })}
              error={errors.specialty}
              data-testid="select-specialty"
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Team"
              required
              register={register('teamContact', { required: true })}
              error={errors.lastNameContact}
              data-testid="team-contact-field"
            />
          </div>
        </div>
        <FieldText
          placeholder="Enter here ..."
          label="Job title"
          required
          register={register('jobTitleContact', { required: true })}
          error={errors.jobTitleContact}
          data-testid="job-title-contact-field"
        />
        <FieldText
          placeholder="Enter here ..."
          label="Email"
          required
          register={register('emailContact', { required: true })}
          error={errors.emailContact}
          data-testid="email-contact-field"
        />
        <div className="row" data-testid="contact-phone-numbers-row">
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              placeholder="+442071234567"
              label="Mobile Phone Number"
              required
              register={register('phoneNumberContact', { required: true })}
              error={errors.phoneNumberContact}
              data-testid="mobile-phone-number-contact-field"
            />
          </div>
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              placeholder="+442071234567"
              label="Work Phone Number"
              register={register('workPhoneNumberContact', { required: false })}
              error={errors.workPhoneNumberContact}
              data-testid="work-phone-number-contact-field"
            />
          </div>
        </div>
      </>
      <>
        <TitleCard data-testid="account-title">Account</TitleCard>
        <div className="row" data-testid="account-name-row">
          <div className="col-md-2">
            <Select
              placeholder="Title"
              label="Title"
              options={titles.map((el) => ({ label: el, value: el }))}
              register={register('titleAccount')}
              error={errors.titleAccount}
              withoutChip
              data-testid="title-account-select"
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="First Name"
              register={register('firstNameAccount', { required: true })}
              error={errors.firstNameAccount}
              data-testid="first-name-account-field"
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="Last Name"
              register={register('lastNameAccount', { required: true })}
              error={errors.lastNameAccount}
              data-testid="last-name-account-field"
            />
          </div>
        </div>

        <FieldText
          placeholder="Enter here ..."
          label="Email"
          register={register('emailAccount', { required: false })}
          error={errors.emailAccount}
          data-testid="email-account-field"
        />
        <div className="row" data-testid="account-phone-numbers-row">
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              placeholder="+442071234567"
              label="Mobile Phone Number"
              register={register('phoneNumberAccount', { required: false })}
              error={errors.phoneNumberAccount}
              data-testid="mobile-phone-number-account-field"
            />
          </div>
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              placeholder="+442071234567"
              label="Work Phone Number"
              register={register('workPhoneNumberAccount', { required: false })}
              error={errors.workPhoneNumberAccount}
              data-testid="work-phone-number-account-field"
            />
          </div>
        </div>
      </>
      <>
        <TitleCard data-testid="bank-details-title">Bank details</TitleCard>
        <FieldText
          placeholder="Enter here ..."
          label="Bank Name"
          register={register('bank.name')}
          error={errors.bank?.name}
          data-testid="bank-name-field"
        />
        <FieldText
          placeholder="Enter here ..."
          label="Bank Account Name"
          register={register('bank.accountName')}
          error={errors.bank?.accountName}
          data-testid="bank-account-name-field"
        />
        <div className="row" data-testid="bank-sort-code-row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Sort Code"
              register={register('bank.sortNumber')}
              error={errors.bank?.sortNumber}
              data-testid="sort-code-field"
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Account Number"
              register={register('bank.accountNumber')}
              error={errors.bank?.accountNumber}
              data-testid="account-number-field"
            />
          </div>
        </div>
      </>
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={onClickClose}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="create-button"
        >
          Create
        </Button>
      </div>
    </FormContainer>
  );
};
