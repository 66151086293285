import React, { createContext, useState, useContext } from 'react';
import { AxiosInstance, getToken, removeToken, setToken } from '../utils';
import { RefreshTokenResponse, User } from '../backend/careo-api';
import { useQueryClient } from '@tanstack/react-query';

interface AuthContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  login: (data: RefreshTokenResponse) => void;
  logout: () => void;
  verifyToken: () => Promise<void>;
  isUserAuthenticated?: boolean;
  isLoading: boolean;
  unLinkEmail: () => void;
}

const logoutRequest = async () => {
  const token = getToken();
  AxiosInstance.auth
    .authControllerLogout({
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch();
};

const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: () => {},
  login: () => {},
  logout: () => {},
  verifyToken: async () => {},
  isUserAuthenticated: undefined,
  isLoading: true,
  unLinkEmail: () => {},
});

export const AuthProvider = ({ children }: any) => {
  const queryClient = useQueryClient();
  const [user, setUser] = useState<User | null>(null);

  const [isUserAuthenticated, setIsUserAuthenticated] = useState<
    boolean | undefined
  >(undefined);

  const [isLoading, setIsLoading] = useState(true);

  const verifyToken = async () => {
    try {
      if (getToken()) {
        await AxiosInstance.auth
          .authControllerVerifyToken()
          .then((response) => {
            setUser(response.data);
            setIsUserAuthenticated(true);
          })
          .catch(() => {
            setUser(null);
            setIsUserAuthenticated(false);
          });
      } else {
        setUser(null);
        setIsUserAuthenticated(false);
      }
    } catch (error) {
      setUser(null);
      setIsUserAuthenticated(false);
    }
    setIsLoading(false);
  };

  const login = (data: RefreshTokenResponse) => {
    setToken(data.accessToken);
    verifyToken();
  };

  const logout = () => {
    logoutRequest();
    removeToken();
    setUser(null);
    queryClient.clear();
    setIsUserAuthenticated(false);
  };

  const unLinkEmail = () => {
    setUser((prev) => {
      if (prev)
        return {
          ...prev,
          emailLinked: false,
        };
      else return null;
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        verifyToken,
        isUserAuthenticated,
        isLoading,
        unLinkEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
