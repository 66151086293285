import React, { useEffect, useState } from 'react';
import { CallIcon, EditIcon, LeftArrowIcon, MessageIcon } from '../../../icons';
import {
  ATag,
  AddToVacancyForm,
  AvailabilityComponent,
  Button,
  CardContainer,
  Table,
  TitlePage,
  UserIcon,
  CandidateGeneral,
  TabPageContainer,
} from '../../../components';
import { CandidateDetailsContainer } from './candidate-details.style';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
} from '../../../utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Candidate, EUserRole } from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/auth.context';
import { useModal } from '../../../contexts/side-modal.context';
import { useConfirm } from '../../../contexts/confirm-modal.context';
import NoteTab from '../../../components/note/note-tab.component';
import { NoteForm } from '../../../components/note/note-form.component';
import { useJobs } from '../../../api-query/hooks';
import { CandidateDocuments } from '../../../components/candidate/tabs/candidate-documents.component';
import { CandidateCompliances } from '../../../components/candidate/tabs/candidate-compliances.component';

export enum ECandidateTabs {
  GENERAL = 'general',
  CONTACT = 'contact',
  AVAILABILITY = 'availability',
  PAYROLL = 'invoice',
  DOCUMENTS = 'documents',
  COMPLIANCE = 'compliance',
  NOTES = 'notes',
  COMPLIANCE_NOTES = 'compliance-notes',
}

const tabs = [
  ECandidateTabs.GENERAL,
  ECandidateTabs.AVAILABILITY,
  ECandidateTabs.PAYROLL,
  ECandidateTabs.DOCUMENTS,
  ECandidateTabs.COMPLIANCE,
  ECandidateTabs.NOTES,
  ECandidateTabs.COMPLIANCE_NOTES,
];

export const CandidateDetailsPage = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { openModal, closeModal } = useModal();
  const { closeConfirm } = useConfirm();

  const { jobsList } = useJobs();

  const [selectedTab, setSelectedTab] = useState(ECandidateTabs.GENERAL);
  const [candidate, setCandidate] = useState<Candidate | null>(null);

  const isPayrollBelongsToUser =
    candidate?.recruiter?._id === user?._id || user?.role === EUserRole.Admin;

  const submitNote = (note: string) => {
    return AxiosInstance.candidates.candidatesControllerCreateNoteCandidate(
      id!,
      {
        text: note,
      },
    );
  };

  const submitComplianceNote = (note: string) => {
    return AxiosInstance.candidates.candidatesControllerCreateComplianceNoteCandidate(
      id!,
      {
        text: note,
      },
    );
  };

  const onClickCreateNote = () => {
    openModal({
      title: 'New Note',
      component: (
        <NoteForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getCandidateDetails();
            closeModal();
          }}
          submitRequest={submitNote}
        />
      ),
    });
  };

  const onClickCreateComplianceNote = () => {
    openModal({
      title: 'New Compliance Note',
      component: (
        <NoteForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getCandidateDetails();
            closeModal();
          }}
          submitRequest={submitComplianceNote}
        />
      ),
    });
  };

  const onClickAddToVacancy = () => {
    openModal({
      title: 'Add to job',
      component: (
        <AddToVacancyForm
          candidate={candidate!}
          jobs={jobsList}
          data-testid="add-to-job-form"
        />
      ),
    });
  };

  const onClickGoBack = () => {
    navigate(-1);
  };

  const deleteNote = async (noteId: string) => {
    AxiosInstance.candidates
      .candidatesControllerDeleteComplianceNoteByCandidateIdAndNoteId(
        id!,
        noteId,
      )
      .then(() => {
        getCandidateDetails();
        closeConfirm();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const deleteComplianceNote = async (noteId: string) => {
    AxiosInstance.candidates
      .candidatesControllerDeleteComplianceNoteByCandidateIdAndNoteId(
        id!,
        noteId,
      )
      .then(() => {
        getCandidateDetails();
        closeConfirm();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getCandidateDetails = () => {
    AxiosInstance.candidates
      .candidatesControllerGetCandidate(id!)
      .then((response) => {
        setCandidate(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    setSelectedTab(
      (location.pathname.split('/')?.[3] as ECandidateTabs) ||
        ECandidateTabs.GENERAL,
    );
  }, [location]);

  useEffect(() => {
    getCandidateDetails();
  }, []);

  if (!candidate) {
    return <></>;
  }

  return (
    <>
      <CandidateDetailsContainer data-testid="candidate-details-container">
        <div className="candidate-header" data-testid="candidate-header">
          <div
            className="back-button"
            onClick={onClickGoBack}
            data-testid="back-button"
          >
            <LeftArrowIcon data-testid="left-arrow-icon" />
          </div>
          <TitlePage data-testid="candidate-title">Detail Candidate</TitlePage>
        </div>

        <CardContainer
          className="page-summary-container"
          data-testid="candidate-summary-container"
        >
          <div className="module-name" data-testid="module-name">
            <UserIcon
              firstName={candidate.firstName}
              lastName={candidate.lastName}
              entity="candidate"
              data-testid="user-icon"
            />
            <div className="module-info" data-testid="module-info">
              <div className="name" data-testid="candidate-name">
                {candidate.firstName} {candidate.lastName}
              </div>
              <div className="availability" data-testid="candidate-city">
                {candidate.address?.city}
              </div>
            </div>
          </div>
          <div className="module-contact" data-testid="module-contact-phone">
            <CallIcon data-testid="call-icon" />
            <div>{candidate.phoneNumber}</div>
          </div>
          <div className="module-contact" data-testid="module-contact-email">
            <MessageIcon data-testid="message-icon" />
            <div>{candidate.email}</div>
          </div>
          <div className="actions-container" data-testid="actions-container">
            <Button
              type="primary"
              onClick={() => onClickAddToVacancy()}
              data-testid="add-to-job-button"
            >
              Add to Vacancy
            </Button>

            <Button
              type="primary"
              onClick={() => navigate(`/messages/candidate/${candidate._id}`)}
              data-testid="message-button"
            >
              Message
            </Button>
          </div>
          <div
            className={`edit-container edit-icon disabled ${
              !isPayrollBelongsToUser && 'disabled'
            }`}
            // onClick={() => isPayrollBelongsToUser && onClickEdit()}
            data-testid="edit-icon-container"
          >
            <EditIcon data-testid="edit-icon" />
          </div>
        </CardContainer>

        <CardContainer
          className="candidate-information-container"
          data-testid="candidate-info-container"
        >
          <TabPageContainer data-testid="tab-page-container">
            <div className="tab-items-list" data-testid="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item text-capitalize ${
                    el === selectedTab && 'active'
                  }`}
                  onClick={() => navigate(`/${ERoute.CANDIDATES}/${id}/${el}`)}
                  data-testid={`tab-item-${el}`}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div
              className="tab-content-container"
              data-testid="tab-content-container"
            >
              {selectedTab === ECandidateTabs.GENERAL && (
                <CandidateGeneral
                  candidate={candidate}
                  data-testid="general-tab-content"
                />
              )}
              {selectedTab === ECandidateTabs.AVAILABILITY && (
                <AvailabilityComponent data-testid="availability-tab-content" />
              )}
              {selectedTab === ECandidateTabs.PAYROLL && (
                <div className="info-card" data-testid="payroll-tab-content">
                  <div
                    className="info-card-title"
                    data-testid="payroll-card-title"
                  >
                    Bank Account
                  </div>
                  <hr />
                  <div
                    className="info-card-content row"
                    data-testid="payroll-card-content"
                  >
                    <div className="col-md-6 row" data-testid="bank-name-row">
                      <div className="info-item-title col-4">Bank Name</div>
                      <div className="info-item-data col-8">
                        {candidate.payrollProvider?.bank?.name || '-'}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="account-name-row"
                    >
                      <div className="info-item-title col-4">Account Name</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.bank?.accountName || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="branch-row">
                      <div className="info-item-title col-4">Branch</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.address?.city || '-'}
                      </div>
                    </div>
                    <div
                      className="col-md-6 row"
                      data-testid="account-number-row"
                    >
                      <div className="info-item-title col-4">
                        Account Number
                      </div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.bank?.accountNumber || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="type-row">
                      <div className="info-item-title col-4">Type</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.employmentType || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row" data-testid="sort-code-row">
                      <div className="info-item-title col-4">Sort Code</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.bank?.sortNumber || '-'}
                      </div>
                    </div>
                  </div>
                  <br />
                  <Table data-testid="payroll-provider-table">
                    <thead>
                      <tr>
                        <th>Payroll Provider</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr data-testid="payroll-provider-row">
                        <td>
                          <div
                            className="payroll-name-checkbox"
                            data-testid="payroll-name-checkbox"
                          >
                            <input
                              type="checkbox"
                              disabled
                              checked={true}
                              data-testid="payroll-checkbox"
                            />
                            <div>
                              {candidate?.payrollProvider?.payrollProviderName}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
              {selectedTab === ECandidateTabs.DOCUMENTS && (
                <CandidateDocuments candidate={candidate} />
              )}
              {selectedTab === ECandidateTabs.COMPLIANCE && (
                <CandidateCompliances />
              )}
              {selectedTab === ECandidateTabs.NOTES && (
                <NoteTab
                  notes={candidate.notes ?? []}
                  onCreateNote={onClickCreateNote}
                  onDeleteNote={deleteNote}
                />
              )}
              {selectedTab === ECandidateTabs.COMPLIANCE_NOTES && (
                <NoteTab
                  notes={candidate.complianceNotes ?? []}
                  onCreateNote={onClickCreateComplianceNote}
                  onDeleteNote={deleteComplianceNote}
                />
              )}
            </div>
          </TabPageContainer>
        </CardContainer>
      </CandidateDetailsContainer>
    </>
  );
};
