import {
  SendWhatsappTemplateDto,
  WhatsappTemplate,
} from '../../backend/careo-api';
import { AxiosInstance } from '../../utils';

export const fetchApprovedTemplates = async () => {
  const response =
    await AxiosInstance.whatsapp.whatsappControllerFetchApprovedTemplates(
      process.env.REACT_APP_NAME!,
    );
  return response.data as WhatsappTemplate[];
};

export const sendApprovedTemplateToListOfRecipients = async (
  sendWhatsappTemplateDto: SendWhatsappTemplateDto,
) => {
  await AxiosInstance.whatsapp.whatsappControllerSendTemplateToRecipientsList(
    process.env.REACT_APP_NAME!,
    sendWhatsappTemplateDto,
  );
};
