import React, { useEffect, useState } from 'react';
import { Select, TitleCard, FieldText, Button, FormContainer } from '../ui';
import {
  countriesWithRegions,
  regionsWithCounties,
  titles,
} from '../../constants';
import { useForm } from 'react-hook-form';
import { UpdatePayrollDto, EUserRole, Payroll } from '../../backend/careo-api';
import { AxiosInstanceErrorResponse } from '../../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleFormsServerErrors } from '../../utils';
import { useAuth } from '../../contexts/auth.context';
import PayrollSchema from '../../utils/validators/payrolls.validator';
import { useModal } from '../../contexts/side-modal.context';
import { usePayrolls, useUsers } from '../../api-query/hooks';

type PayrollFormProps = {
  selectedPayroll?: Payroll;
};

export const PayrollForm = ({ selectedPayroll }: PayrollFormProps) => {
  const { user } = useAuth();
  const { closeModal } = useModal();
  const { savePayroll } = usePayrolls();
  const { usersList } = useUsers();

  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    setError,
    setFocus,
  } = useForm<UpdatePayrollDto>({
    resolver: yupResolver(PayrollSchema as any),
  });
  const formValues = watch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClickSubmit = async () => {
    setIsSubmitting(true);
    const values = getValues();

    await savePayroll({
      payrollData: {
        ...values,
        employmentType: '-',
        standardWorkingHoursPerDay: '-',
      } as any,
      selectedPayroll,
    })
      .then(() => {
        closeModal();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
      });
  };

  useEffect(() => {
    if (selectedPayroll) {
      reset({
        ...selectedPayroll,
        userId: selectedPayroll.user?._id,
      });
    }
  }, []);

  useEffect(() => {
    const adminId = usersList.find((el) => el.role === EUserRole.Admin)?._id;
    setValue('userId', adminId);
  }, [usersList]);

  return (
    <FormContainer data-testid="form-container">
      <Select
        placeholder="Select admin"
        required
        label="Admin"
        options={usersList.map((el) => ({
          label: el.firstName + ' ' + el.lastName,
          value: el._id,
        }))}
        register={register('userId')}
        error={errors.userId}
        control={control}
        value={user?.role !== EUserRole.Admin ? user?._id : undefined}
        disabled
        data-testid="recruiter-select"
      />
      <TitleCard data-testid="client-details-title-card">
        Client Details
      </TitleCard>
      <FieldText
        placeholder="Enter here ..."
        label="Payroll Provider Name"
        required
        register={register('payrollProviderName')}
        error={errors.payrollProviderName}
        data-testid="payroll-provider-name-input"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Vat number"
        type="number"
        register={register('vatNumber')}
        error={errors.vatNumber}
        data-testid="vat-number-input"
      />
      <TitleCard data-testid="primary-contact-title-card">
        Primary Contact
      </TitleCard>
      <div className="row">
        <div className="col-md-2">
          <Select
            placeholder="Title"
            label="Title"
            options={titles.map((el) => ({ label: el, value: el }))}
            register={register('title')}
            error={errors.title}
            control={control}
            withoutChip
            data-testid="title-select"
          />
        </div>
        <div className="col-md-5">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('leadContactFirstName')}
            error={errors.leadContactFirstName}
            data-testid="first-name-input"
          />
        </div>
        <div className="col-md-5">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('leadContactLastName')}
            error={errors.leadContactLastName}
            data-testid="last-name-input"
          />
        </div>
      </div>
      <FieldText
        placeholder="Enter here ..."
        label="Job Title"
        register={register('leadContactJobTitle')}
        error={errors.leadContactJobTitle}
        data-testid="job-title-input"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Email Address"
        required
        register={register('leadContactEmail')}
        error={errors.leadContactEmail}
        data-testid="email-address-input"
      />
      <div className="row">
        <div className="col-md-6">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.leadContactMobilePhoneNumber}
            placeholder="+442071234567"
            label="Mobile Phone Number"
            required
            register={register('leadContactMobilePhoneNumber')}
            error={errors.leadContactMobilePhoneNumber}
            data-testid="mobile-phone-number-input"
          />
        </div>
        <div className="col-md-6">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.leadContactWorkPhoneNumber}
            placeholder="+442071234567"
            label="Work Phone Number"
            register={register('leadContactWorkPhoneNumber')}
            error={errors.leadContactWorkPhoneNumber}
            data-testid="work-phone-number-input"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Website"
            register={register('website')}
            error={errors.website}
            data-testid="website-input"
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Fax Number"
            register={register('leadContactFax')}
            error={errors.leadContactFax}
            data-testid="fax-number-input"
          />
        </div>
      </div>
      <>
        <TitleCard data-testid="address-title-card">Address</TitleCard>
        <FieldText
          placeholder="Enter here ..."
          label="Street Address"
          register={register('address.street')}
          error={errors?.address?.street}
          data-testid="street-address-input"
        />
        <FieldText
          placeholder="Enter here ..."
          label="City"
          register={register('address.city')}
          error={errors?.address?.city}
          data-testid="city-input"
        />
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Select country"
              label="Country"
              options={countriesWithRegions.map((el) => ({
                label: el.country,
                value: el.country,
              }))}
              register={register('address.country')}
              error={errors?.address?.country}
              onChange={() => {
                setValue('address.region', '');
                setValue('address.county', '');
              }}
              control={control}
              data-testid="country-select"
            />
          </div>
          <div className="col-md-6">
            <Select
              placeholder="Select Region"
              label="Region"
              required
              options={
                countriesWithRegions
                  .find((el) => el.country === formValues.address?.country)
                  ?.regions?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.region')}
              error={errors?.address?.region}
              disabled={!formValues.address?.country}
              control={control}
              data-testid="region-select"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Select County"
              label="County"
              options={
                regionsWithCounties
                  .find((el) => el.region === formValues.address?.region)
                  ?.counties?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.county')}
              error={errors?.address?.county}
              disabled={!formValues.address?.region}
              control={control}
              data-testid="county-select"
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Postcode"
              register={register('address.zip')}
              error={errors?.address?.zip}
              data-testid="postcode-input"
            />
          </div>
        </div>
      </>
      <TitleCard data-testid="bank-details-title-card">Bank details</TitleCard>
      <FieldText
        placeholder="Enter here ..."
        label="Bank Name"
        register={register('bank.name')}
        error={errors.bank?.name}
        data-testid="bank-name-input"
      />
      <FieldText
        placeholder="Enter here ..."
        label="Bank Account Name"
        register={register('bank.accountName')}
        error={errors.bank?.accountName}
        data-testid="bank-account-name-input"
      />
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Sort Code"
            register={register('bank.sortNumber')}
            error={errors.bank?.sortNumber}
            data-testid="sort-code-input"
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Account Number"
            register={register('bank.accountNumber')}
            error={errors.bank?.accountNumber}
            data-testid="account-number-input"
          />
        </div>
      </div>
      <div className="form-actions" data-testid="form-actions">
        <Button
          onClick={() => closeModal()}
          data-testid="cancel-button"
          type="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit(onClickSubmit)}
          disabled={isSubmitting}
          data-testid="update-button"
        >
          Submit
        </Button>
      </div>
    </FormContainer>
  );
};
