import React, { useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  HeaderPageContainer,
  MultipleSelect,
  VacancyForm,
  Pagination,
  SearchInput,
  SubTitlePage,
  Table,
  TitlePage,
  UploadButton,
} from '../../components';
import {
  CalendarIcon,
  DeleteIcon,
  EditIcon,
  EyeIcon,
  PlusIcon,
  SortIcon,
} from '../../icons';
import {
  TUIType,
  getGradesOfSelectedLevel,
  jobLevelWithGrades,
  regionsWithCounties,
} from '../../constants';
import {
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterVacancy,
  filterVacancies,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortVacancies,
  uploadVacanciesCsvRequest,
} from '../../utils';
import { useNavigate } from 'react-router-dom';
import { EJobStatus, Job } from '../../backend/careo-api';
import { toast } from 'react-toastify';
import { useModal } from '../../contexts/side-modal.context';
import { useConfirm } from '../../contexts/confirm-modal.context';
import { useJobs } from '../../api-query/hooks/useJobs';

const itemsPerPage = 8;

const jobStatus: { [key in EJobStatus]: TUIType } = {
  [EJobStatus.Hired]: 'success',
  [EJobStatus.Pending]: 'warning',
  [EJobStatus.Active]: 'primary',
  [EJobStatus.Closed]: 'danger',
};

export const VacanciesListPage = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { openConfirm, closeConfirm } = useConfirm();

  const { jobsList, isLoading, deleteJob } = useJobs();
  const [isUploading, setIsUploading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<TFilterVacancy>({
    search: '',
    regions: [],
    grades: [],
    levels: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const onClickCreate = () => {
    openModal({
      title: 'New Vacancy',
      component: <VacancyForm data-testid="new-job-form" />,
    });
  };

  const onClickEdit = (job: Job) => {
    openModal({
      title: 'Update Vacancy',
      component: (
        <VacancyForm selectedJob={job} data-testid="update-job-form" />
      ),
    });
  };

  const onDeleteConfirm = (job: Job) => {
    openConfirm({
      title: 'Delete Vacancy',
      component: (
        <>
          Do you want to delete <b>this job</b>?
        </>
      ),
      onConfirm: () =>
        deleteJob(job)
          .then(() => closeConfirm())
          .catch(() => {}),
    });
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.JOBS}/${id}`);
  };

  const onClickAvailability = (id: string) => {
    navigate(`/${ERoute.JOBS}/${id}/applications`);
  };

  const uploadVacanciesCsv = async (file: File) => {
    setIsUploading(true);
    await uploadVacanciesCsvRequest(file)
      .then(() => {
        toast.success('Vacancies uploaded successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsUploading(false);
  };

  // Apply filtering and sorting
  const filteredAndSortedData = useMemo(() => {
    const filteredData = filterVacancies(jobsList, filter);
    return sortVacancies(filteredData, sort);
  }, [jobsList, filter, sort]);

  // Paginate the data
  const paginatedData = useMemo(() => {
    return getItemsOfPage(filteredAndSortedData, currentPage, itemsPerPage);
  }, [filteredAndSortedData, currentPage]);

  const totalPages = useMemo(() => {
    return getNumberOfPages(filteredAndSortedData.length, itemsPerPage);
  }, [filteredAndSortedData]);

  return (
    <>
      <CardContainer data-testid="card-container">
        <HeaderPageContainer data-testid="header-page-container">
          <div className="left-container" data-testid="left-container">
            <TitlePage data-testid="title-page">Vacancies</TitlePage>
            <SubTitlePage data-testid="sub-title-page">
              Manage your Vacancy
            </SubTitlePage>
          </div>
          <div className="right-container" data-testid="right-container">
            <UploadButton
              accept=".csv"
              onUpload={uploadVacanciesCsv}
              data-testid="upload-button"
              isUploading={isUploading}
            >
              Upload
            </UploadButton>
            <Button
              type="primary"
              onClick={() => onClickCreate()}
              data-testid="add-new-button"
            >
              <PlusIcon data-testid="plus-icon" /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container" data-testid="filter-container">
          <SearchInput
            placeholder="Search job"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
            data-testid="search-input"
          />
          <MultipleSelect
            placeholder="All Regions"
            options={regionsWithCounties.map((el) => ({
              label: el.region,
              value: el.region,
            }))}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                regions: values ?? [],
              }));
            }}
            data-testid="regions-select"
          />
          <MultipleSelect
            placeholder="All Levels"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, levels: values ?? [] }));
            }}
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
            data-testid="levels-select"
          />
          <MultipleSelect
            placeholder="All Grades"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, grades: values ?? [] }));
            }}
            options={getGradesOfSelectedLevel(filter.levels).map((el) => ({
              label: el,
              value: el,
            }))}
            disabled={!filter.levels.length && !filter.grades.length}
            data-testid="grades-select"
          />
        </div>
        <div
          className="data-table-container"
          data-testid="data-table-container"
        >
          <Table data-testid="job-table">
            <thead>
              <tr>
                {/* <th
                  className="checkbox-table"
                  data-testid="checkbox-table-header"
                >
                  <input type="checkbox" data-testid="checkbox-select-all" />
                </th> */}
                <th
                  onClick={() => onSelectSort('specialty', setSort)}
                  data-testid="job-column"
                >
                  <div>
                    <label>Vacancy</label>
                    <SortIcon
                      value={sort.key === 'specialty' ? sort.value : ''}
                      data-testid="job-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('client-name', setSort)}
                  data-testid="client-column"
                >
                  <div>
                    <label>Client</label>
                    <SortIcon
                      value={sort.key === 'client-name' ? sort.value : ''}
                      data-testid="client-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('client-firstName', setSort)}
                  data-testid="lead-contact-column"
                >
                  <div>
                    <label>Lead Contact</label>
                    <SortIcon
                      value={sort.key === 'client-firstName' ? sort.value : ''}
                      data-testid="lead-contact-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('user-firstName', setSort)}
                  data-testid="line-manager-column"
                >
                  <div>
                    <label>Line Manager</label>
                    <SortIcon
                      value={sort.key === 'user-firstName' ? sort.value : ''}
                      data-testid="line-manager-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('status', setSort)}
                  data-testid="job-status-column"
                >
                  <div>
                    <label>Job Status</label>
                    <SortIcon
                      value={sort.key === 'status' ? sort.value : ''}
                      data-testid="job-status-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('level', setSort)}
                  data-testid="job-title-column"
                >
                  <div>
                    <label>Job Title</label>
                    <SortIcon
                      value={sort.key === 'level' ? sort.value : ''}
                      data-testid="job-title-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('region', setSort)}
                  data-testid="region-column"
                >
                  <div>
                    <label>Region</label>
                    <SortIcon
                      value={sort.key === 'region' ? sort.value : ''}
                      data-testid="region-sort-icon"
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('status', setSort)}
                  data-testid="client-status-column"
                >
                  <div>
                    <label>Client Status</label>
                    <SortIcon
                      value={sort.key === 'status' ? sort.value : ''}
                      data-testid="client-status-sort-icon"
                    />
                  </div>
                </th>
                <th data-testid="actions-column">
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr data-testid="loading-row">
                  <td
                    colSpan={100}
                    className="text-center"
                    data-testid="loading-text"
                  >
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((job) => {
                        return (
                          <tr key={job._id} data-testid={`job-row-${job._id}`}>
                            {/* <td className="checkbox-table">
                              <input
                                type="checkbox"
                                data-testid={`checkbox-job-${job._id}`}
                              />
                            </td> */}
                            <td data-testid={`job-name-${job._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>{job?.specialty}</div>
                                  <div className="email">
                                    {job?.shift ? job?.shift + ' shift' : '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`client-name-${job._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>{job?.client?.clientName}</div>
                                  <div className="email">
                                    {job?.client?.trust?.name || '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`lead-contact-${job._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>
                                    {job?.client?.firstName}{' '}
                                    {job?.client?.lastName}
                                  </div>
                                  <div className="email">
                                    {job?.client?.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`line-manager-${job._id}`}>
                              {job.user ? (
                                `${job.user?.firstName} ${job.user?.lastName}`
                              ) : (
                                <Badge
                                  type={'warning'}
                                  data-testid={`unassigned-badge-${job._id}`}
                                >
                                  Unassigned
                                </Badge>
                              )}
                            </td>
                            <td data-testid={`job-status-${job._id}`}>
                              <Badge
                                type={
                                  jobStatus[job.status ?? EJobStatus.Pending]
                                }
                                data-testid={`job-status-badge-${job._id}`}
                              >
                                {job.status ?? EJobStatus.Pending}
                              </Badge>
                            </td>
                            <td data-testid={`job-title-${job._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>{job?.level}</div>
                                  <div className="email">{job?.grade}</div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`region-${job._id}`}>
                              <div className="name-item">
                                <div>
                                  <div>
                                    {job?.client?.address?.region || '-'}
                                  </div>
                                  <div className="email">
                                    {job?.client?.address?.county || '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td data-testid={`client-status-${job._id}`}>
                              <Badge
                                type={
                                  job?.client?.isProfileComplete
                                    ? 'success'
                                    : 'danger'
                                }
                                data-testid={`client-status-badge-${job._id}`}
                              >
                                {job?.client?.isProfileComplete
                                  ? 'Active'
                                  : 'Not Active'}
                              </Badge>
                            </td>
                            <td data-testid={`actions-${job._id}`}>
                              <div className="action-item">
                                <div
                                  className="download-icon"
                                  onClick={() => onClickAvailability(job._id)}
                                  data-testid={`calendar-icon-${job._id}`}
                                >
                                  <CalendarIcon title="Manage job availabilities" />
                                </div>
                                <div
                                  className="view-icon"
                                  onClick={() => onClickView(job._id)}
                                  data-testid={`eye-icon-${job._id}`}
                                >
                                  <EyeIcon />
                                </div>
                                <div
                                  className="edit-icon"
                                  onClick={() => onClickEdit(job)}
                                  data-testid={`edit-icon-${job._id}`}
                                >
                                  <EditIcon />
                                </div>
                                <div
                                  className="delete-icon"
                                  onClick={() => onDeleteConfirm(job)}
                                  data-testid={`delete-icon-${job._id}`}
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr data-testid="no-items-row">
                      <td
                        colSpan={100}
                        className="text-center"
                        data-testid="no-items-text"
                      >
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          totalEntries={filteredAndSortedData.length}
          data-testid="pagination"
        />
      </CardContainer>
    </>
  );
};
