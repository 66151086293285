import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  deleteClient,
  getClients,
  createClient,
  updateClient,
  getClientById,
} from '../api/clients.api';
import {
  CreateClientDto,
  Client,
  ESocketRefreshModule,
} from '../../backend/careo-api';

type SaveClientVariables = {
  clientData: CreateClientDto;
  selectedClient?: Client;
};

type UseClientProps = {
  clientsList: Client[];
  isLoading: boolean;

  saveClient: (variables: SaveClientVariables) => Promise<void>;
  deleteClient: (client: Client) => Promise<void>;
  findClientById: (clientId: string) => Promise<Client>;
};

export const useClients = (): UseClientProps => {
  // Fetch clients (React Query caches it)
  const { data: clients, isLoading } = useQuery({
    queryKey: [ESocketRefreshModule.CLIENTS],
    queryFn: getClients,
  });

  // Delete client mutation
  const deleteMutation = useMutation({
    mutationFn: deleteClient,
    onSuccess: () => {
      toast.success('Client removed successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  // Create or Update Client Mutation
  const saveClientMutation = useMutation({
    mutationFn: ({
      selectedClient,
      clientData,
    }: {
      selectedClient?: Client;
      clientData: CreateClientDto;
    }) => {
      return (
        selectedClient
          ? updateClient(selectedClient._id, clientData)
          : createClient(clientData)
      ).then(() => {});
    },
    onSuccess: () => {
      toast.success('Client saved successfully');
    },
    onError: (error: any) => {
      toast.error(error?.message || 'Something went wrong');
    },
  });

  return {
    clientsList: clients ?? [],
    isLoading,
    deleteClient: deleteMutation.mutateAsync,
    saveClient: saveClientMutation.mutateAsync,
    findClientById: getClientById,
  };
};
